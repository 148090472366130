import PostCard from "components/pages/Blog/components/PostCard"
import RichTextRenderer from "components/UI/RichTextRenderer"
import TrialAdvert from "components/Adverts/TrialAdvert"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Folder, Tag } from "grommet-icons"
import React from "react"
import moment from "moment"
import Moment from "react-moment"
import styled from "styled-components"
import SEO from "../components/SEO"
import Layout from "../layouts/Main"
import { FONT_FAMILIES } from "../styles/Theme"

const Wrapper = styled.div`
  background-image: linear-gradient(90deg, #572779, #45106b);
  color: rgb(70, 70, 70);
  padding-bottom: 40px;
`

const Header = styled.div`
  position: relative;
  background-image: linear-gradient(90deg, #642d8c, #45106b);
  height: 310px;
  min-height: 250px;
  margin-bottom: 50px;
  > :first-child {
    height: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
  }
  color: #fff;
  @media (max-width: 1120px) {
    margin-bottom: 0;
  }
`

const Content = styled.div`
  background-color: #fff;
  padding: 0px 36px;
  max-width: 860px;
  margin: 0 auto;
  font-size: 1.3rem;
  figure {
    max-width: 100%;
  }
  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
  }
  .advert {
    margin: 0 -36px 15px -36px;
  }
  @media (max-width: 760px) {
    padding: 20px;
  }
`

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  > :last-child {
    margin-top: auto;
  }
  z-index: 2;
  @media (max-width: 1120px) {
    width: 100%;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    > :last-child {
      margin-top: auto;
    }
  }
`

const BigTitle = styled.h1``

const BlogBanner = styled.div`
  font-size: 1.9rem;
  line-height: 2.3;
  color: #fff;
  ${FONT_FAMILIES.rammetto};
  background-image: linear-gradient(90deg, #45106b, #572779);
  > :first-child {
    max-width: 1100px;
    margin: 0 auto;
  }
  @media (max-width: 1120px) {
    padding: 0 20px;
    padding-top: 50px;
    > :first-child {
      font-size: 22px !important;
    }
  }
  @media (max-width: 370px) {
    > :first-child {
      font-size: 22px !important;
    }
  }
`

const PostInformation = styled.div`
  > * {
    margin-bottom: 15px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
`

const Author = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 10px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  color: #f4cf5d;
  margin-top: auto;
`

const MoreInfo = styled.div``

const MaskedImage = styled.div`
  position: relative;
  width: 70%;
  align-self: stretch;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  > :first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  @media (max-width: 1120px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    > :first-child {
      clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      ::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`

const Groups = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid rgba(0, 0, 0, 0.06);
  padding: 25px 0;
  font-size: 1rem;
`

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > * {
    margin-right: 8px;
    margin-bottom: 6px;
  }
`

const RecommendedSection = styled.div`
  color: #fff;
  max-width: 1100px;
  margin: 30px auto;
  .title {
    margin-bottom: 25px;
    font-family: "Rammetto One";
    font-size: 26px;
    font-weight: bold;
  }
  .posts {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    @media (max-width: 1120px) {
      padding: 0 20px;
    }
  }
`

const BlogPostTemplate = ({ data }: any) => {
  const { strapiBlogPosts, defaultRecommendedPosts } = data
  const {
    slug,
    title,
    content,
    recommendedArticles,
    originalPublishDate,
    published_at,
    updated_at,
    author,
    mainThumbnail,
    thumbnail,
    blog_categories: categories = [],
    blog_tags: tags = [],
    excerpt,
  } = strapiBlogPosts

  const recommendedPosts =
    recommendedArticles?.length > 0
      ? recommendedArticles
      : (defaultRecommendedPosts?.edges || []).map(e => e?.node)
  return (
    <Layout>
      <SEO
        title={title}
        titleTemplate="%s | Night Zookeeper Blog"
        image={mainThumbnail?.url || thumbnail?.url}
        url={`${data.site.siteMetadata.siteUrl}/blog/articles/${slug}`}
        canonical={`${data.site.siteMetadata.siteUrl}/blog/articles/${slug}`}
        description={excerpt}
      >
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Article",
            "name": "${title}",
            "author":{
              "@type": "Person",
              "name": "${author.fullName}"
            },
            "datePublished": "${moment(
              originalPublishDate || published_at
            ).format("YYYY-MM-DD")}",
            "publisher":{
              "@type": "Organization",
              "name": "Night Zookeeper"
            }
          }`}
        </script>
      </SEO>
      <Wrapper>
        <BlogBanner>
          <div>
            <Link to="/blog">Night Zookeeper Blog</Link>
          </div>
        </BlogBanner>
        <Header>
          <div>
            <TitleSection>
              <BigTitle>{title}</BigTitle>
              <PostInformation>
                {author && (
                  <Author>
                    <GatsbyImage
                      image={
                        getImage(author.avatar.localFile) as IGatsbyImageData
                      }
                      alt={`${author.name} Avatar`}
                    />
                    <div>{author.fullName}</div>
                  </Author>
                )}
                <MoreInfo>
                  {content && content.split(" ").length} words -{" "}
                  <Moment format="LL">
                    {originalPublishDate || published_at}
                  </Moment>
                </MoreInfo>
                {originalPublishDate < updated_at && (
                  <MoreInfo>
                    Last updated <Moment format="LL">{updated_at}</Moment>
                  </MoreInfo>
                )}
              </PostInformation>
            </TitleSection>
            <MaskedImage>
              {thumbnail?.localFile?.childImageSharp ? (
                <GatsbyImage
                  image={getImage(thumbnail.localFile) as IGatsbyImageData}
                  alt={`${title} thumbnail`}
                />
              ) : (
                <img src={thumbnail?.url} alt={`${title} thumbnail`} />
              )}
            </MaskedImage>
          </div>
        </Header>
        <Content>
          <RichTextRenderer>{content}</RichTextRenderer>
          <div className="advert">
            <TrialAdvert />
          </div>
          <Groups>
            {categories.length > 0 && (
              <Group>
                <Folder color="#52A0CF" size="17px" />
                {categories.map((c, i) => (
                  <Link key={c.slug} to={`/blog/categories/${c.slug}`}>
                    {c.title}
                    {i < categories.length - 1 ? ", " : ""}
                  </Link>
                ))}
              </Group>
            )}
            {tags.length > 0 && (
              <Group>
                <Tag color="#52A0CF" size="17px" />
                {tags.map((t, i) => (
                  <Link key={t.slug} to={`/blog/tags/${t.slug}`}>
                    {t.title}
                    {i < tags.length - 1 ? ", " : ""}
                  </Link>
                ))}
              </Group>
            )}
          </Groups>
        </Content>
        {recommendedPosts && (
          <RecommendedSection>
            <div className="title">Recommended posts</div>
            <div className="posts">
              {recommendedPosts.map(post => (
                <PostCard key={post.slug} {...post} />
              ))}
            </div>
          </RecommendedSection>
        )}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiBlogPosts(slug: { eq: $slug }) {
      slug
      title
      content
      originalPublishDate
      published_at
      updated_at
      thumbnail {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
      }
      mainThumbnail {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
      }
      blog_categories {
        slug
        title
      }
      recommendedArticles: recommended_articles {
        title
        slug
        thumbnail {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 500)
            }
          }
        }
        excerpt
        originalPublishDate
        published_at
        author {
          fullName
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 100)
              }
            }
          }
        }
      }
      excerpt
      author {
        slug
        fullName
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100)
            }
          }
        }
      }
    }
    defaultRecommendedPosts: allStrapiBlogPosts(
      filter: { slug: { ne: $slug }, originalPublishDate: { ne: null } }
      sort: { fields: originalPublishDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          thumbnail {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 500)
              }
            }
          }
          excerpt
          originalPublishDate
          published_at
          author {
            fullName
            avatar {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 100)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPostTemplate
