import StrapiButton from "components/Strapi/StrapiButton"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 25px;
  display: flex;
  color: #fff;
  align-items: center;
  .bg {
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    > :first-child {
      height: 100%;
      width: 100%;
    }
  }

  > :nth-child(2) {
    place-self: flex-end;
  }

  @media (max-width: 860px) {
    flex-direction: column-reverse;
    text-align: center;
    > :nth-child(2) {
      place-self: center;
    }
  }
`

const Content = styled.div`
  z-index: 0;
  min-width: 400px;
  padding-left: 50px;
  padding: 15px 10px 20px 20px;
  font-size: 15px;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 15px;
    > * {
      margin-bottom: 6px;
    }
    > :last-child {
      margin-bottom: 0px;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    font-family: "Rammetto One", cursive;

    @media (max-width: 660px) {
      font-size: 20px;
    }
  }
  .heart-icon {
    width: 16px;
    height: 16px;
    margin-right: 7px;
  }
`

const TrialAdvert = () => {
  const { banner, boygirl } = useStaticQuery(graphql`
    {
      banner: file(relativePath: { eq: "banner-00.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1280)
        }
      }
      boygirl: file(relativePath: { eq: "boy-girl.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 320)
        }
      }
    }
  `)
  const GreenHeartSVG = () => (
    <svg
      className="heart-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="#84b051"
    >
      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
    </svg>
  )

  return (
    <Wrapper>
      <div className="bg">
        <GatsbyImage image={getGatsbyImage(banner)} alt="Banner" />
      </div>
      <GatsbyImage
        image={getGatsbyImage(boygirl)}
        alt="Children holding a tablet"
      />
      <Content>
        <h3>Make Reading & Writing Fantastically Fun!</h3>
        <ul>
          <li>
            <GreenHeartSVG />
            Award-winning reading & writing program for kids
          </li>
          <li>
            <GreenHeartSVG />
            Improves spelling, grammar, punctuation & vocabulary
          </li>
          <li>
            <GreenHeartSVG />
            Over 1,000 different learning games and activities
          </li>
        </ul>
        <StrapiButton
          theme="primary"
          size="regular"
          targetUrl="/parents"
          value="Learn More"
        />
      </Content>
    </Wrapper>
  )
}

export default TrialAdvert
